// ---------------------------------------------------------------------
// Global JavaScript
// Authors: Andrew Ross & a little help from my friends
// ---------------------------------------------------------------------

var andrewrossco = andrewrossco || {};

(function($, APP) {

    $(function() {
        APP.App.init();
        APP.Viewport.init();
        APP.Modal.init();
        APP.Scroller.init();
        APP.Carousel.init();
    });

// ---------------------------------------------------------------------
// Browser and Feature Detection
// ---------------------------------------------------------------------

APP.App = {
    userAgent: undefined,
    $html: undefined,

    init: function() {
        APP.Features = APP.Features || {};
        this.userAgent = navigator.userAgent.toLowerCase();
        this.$html = $('html');
        this.noTouch();
        this.isTouch();
        this.isNewIE();
        this.isIE();

        $('body').addClass('page-loaded');

		var el = $('.site-header'),
			navPos = el.offset().top,
			w = $(window),
			d = $(document);

		d.scroll(function() {
			if (w.scrollTop() > 50) {
				if( !el.hasClass('is-sticky') ){
					el.addClass('is-sticky');
				}
			} else {
				if( el.hasClass('is-sticky') ){
					el.removeClass('is-sticky');
				}
			}

		});

        var lastScrollTop = 0;

        $(window).scroll(function(event){

            var st = $(this).scrollTop();
            if (st > lastScrollTop){
                //scroll down
                if( el.hasClass('scrolling-up') ){
					el.removeClass('scrolling-up');
				}
            } else {
                //scroll down
                if( !el.hasClass('scrolling-up') ){
					el.addClass('scrolling-up');
				}
            }
            lastScrollTop = st;

        });

        var burger = $('.mobile-menu-trigger'),
            mask = $('.screen');
        $('.mobile-menu-trigger, .screen').click(function(){

			if( $('body').hasClass('menu-is-active') ) {
			  	burger.removeClass('is-active');
				mask.fadeOut(300);
			    $('body').removeClass('menu-is-active');
			  } else {
			  	mask.fadeIn(300);
				burger.addClass('is-active');
			    $('body').addClass('menu-is-active');
			  }
		});
    },

    noTouch: function() {
        if ( ! ('ontouchstart' in window) ) {
            APP.Features.noTouch = false;
            this.$html.addClass('noTouch');
            return;
        }
        APP.Features.noTouch = false;
    },

    isTouch: function() {
        if ( 'ontouchstart' in window ) {
            APP.Features.isTouch = false;
            this.$html.addClass('isTouch');
            return;
        }
        APP.Features.isTouch = false;
    },

    isNewIE: function() {
		if (document.documentMode || /Edge/.test(navigator.userAgent)) {
            if(navigator.appVersion.indexOf('Trident') === -1) {
                this.$html.addClass('isEDGE');
            } else {
                this.$html.addClass('isIE isIE11');
            }
            return;
        }
        APP.Features.isNewIE = false;
    },

    isIE: function() {
        if( navigator.appVersion.indexOf("MSIE") !== -1 ) {
            this.$html.addClass('isIE');
            APP.Features.isIE = true;
            return;
        }
        APP.Features.isIE = false;
    }

};



// ---------------------------------------------------------------------
// Detect when an element is in the viewport
// ---------------------------------------------------------------------

APP.Viewport = {

    init: function() {
		$.fn.isOnScreen = function(){
			var elementTop = $(this).offset().top,
				elementBottom = elementTop + $(this).outerHeight(),
				viewportTop = $(window).scrollTop(),
				viewportBottom = viewportTop + $(window).height();
			return elementBottom > viewportTop && elementTop < viewportBottom;
		};

		var items = document.querySelectorAll('*[data-animate-in], *[data-detect-viewport]'),
            paths = $('.draw-in path');

        TweenLite.to(paths, 0, {drawSVG:"0"});

		function detection(el) {
            var hasKids = false;

            if( el.hasClass('draw-in') ){
                hasKids = true;
                var kids = el.find('path');
            }

			if( el.isOnScreen() ){
				if(!el.hasClass('in-view')){
					el.addClass('in-view');

                    if( hasKids == true ){
                        TweenLite.to(kids, 1, {drawSVG:"100%"});
                    }
				}
			} else {
				if(el.hasClass('in-view')){
					el.removeClass('in-view');

                    if( el.hasClass('draw-in') ){
                        if( hasKids == true ){
                            TweenLite.to(kids, 1, {drawSVG:"0"});
                        }
                    }
				}
			}
		}

		$(window).on("resize scroll", function(){
			for(var i = 0; i < items.length; i++) {
				var el = $( items[i] );
				detection(el);
			}
		});

		$(document).ready(function(){
			for(var i = 0; i < items.length; i++) {
				var d = 0,
					el = $( items[i] );
				if( items[i].getAttribute('data-animate-in-delay') ) {
					d = items[i].getAttribute('data-animate-in-delay') / 1000 + 's';
				} else {
					d = 0;
				}
				el.css('transition-delay', d);

				 detection(el);
			}
		});
    }
};



// ---------------------------------------------------------------------
// Modal
// ---------------------------------------------------------------------

APP.Modal = {

    init: function() {
        if( $('.meal') ) {
            this.bind();
        } else {
            return;
        }
    },

    bind: function() {

        $(document).ready(function(){

            var trigger = $('.meal-preview'),
                meal = $('.meal'),
                code,
                target;

            trigger.click(function(e){
                e.preventDefault();

                code = $(this).attr('data-meal');
                target = $('#' + code);

                if( target ){
                    target.addClass('is-active');
                    $('body').addClass('modal-is-active');
                }
            });
        });

        var meal = $('.meal'),
            close = $('.modal__close'),
            next = $('.js-modal-change'),
            code,
            par,
            target;

        close.click(function(e){
            e.preventDefault();
            $(this).parents('.meal').removeClass('is-active');
            $('body').removeClass('modal-is-active');
        });

        next.click(function(e){
            e.preventDefault();

            code = $(this).attr('data-meal');
            par = $(this).parents('.meal');

            target = $('#' + code);


            par.addClass('drop-back');

            if( target ){
                target.addClass('is-active');
                $('body').addClass('modal-is-active');
            } else {
                $('body').removeClass('modal-is-active');
            }

            setTimeout(function(){
                par.removeClass('is-active');
            }, 600);
            setTimeout(function(){
                par.removeClass('drop-back');
            }, 1200);

        });




    }
};



// ---------------------------------------------------------------------
// Scroll
// ---------------------------------------------------------------------

APP.Scroller = {

    init: function() {
        if( document.getElementById("slider") ) {
            this.bind();
        } else {
            return;
        }
    },

    bind: function() {

        var slider = document.getElementById('slider'),
            timeline = new TimelineMax({ repeat: -1 }),
            ww = window.innerWidth,
            dist = (ww * 1.25) * 2,
            first = slider.children[0];

        slider.appendChild(first.cloneNode(true));
        slider.appendChild(first.cloneNode(true));

        timeline.to(slider, 80, {ease: Power0.easeNone, xPercent:-66.66666}).to(slider, 0, {ease: Power0.easeNone, x:0});

        slider.addEventListener("mouseover",function() {
            timeline.pause();
        },false);

        slider.addEventListener("mouseout",function() {
            timeline.resume();
        },false);
    }
};



// ---------------------------------------------------------------------
// Carousel
// ---------------------------------------------------------------------

APP.Carousel = {

    init: function() {
        if( $('.carousel') ) {
            this.bind();
        } else {
            return;
        }
    },

    bind: function() {

        var carousel = $('.carousel');

        carousel.each(function(){
            var el = $(this),
                speed = el.attr('data-speed'),
                first = el.find('li:first');

            var loop = function(){
                el.find('.is-active').removeClass().next().add(first).last().addClass('is-active');

            }
            setInterval(loop, speed);
        });

        var first = $('g.days > *:first-child'),
            circles = $('g.days > *'),
            last = $('g.days > *:last-child');

        var days = function(){
            if( last.hasClass('is-active') ){
                circles.removeClass('is-active');
            } else {
                $('g.days > .is-active:last').addClass('is-active').next().add(first).addClass('is-active');
            }
        }
        setInterval(days, 1000);


        var checks = $('.ex'),
            checkList = $('#check-group');

        //console.log(random);

        var showHide = function(){
            var random = Math.floor(Math.random() * checks.length + 1);
            checkList.find('.ex:nth-child(' + random + ')').addClass('asfsaf').fadeToggle(300);
        }
        setInterval(showHide, 2000);

        var plates = document.getElementById('icon-plates'),
            smPlates = $('#icon-plates span'),
            myTimeline = new TimelineMax({ repeat: -1 });

        smPlates.each(function(){
            var el = $(this),
                elx = el.attr('data-x'),
                ely = el.attr('data-y'),
                elTimeline = new TimelineMax({ repeat: -1 });

            //elTimeline.to().to();
            elTimeline.add(TweenMax.to(el, 1, {delay: 2, width:"28px", height:"28px", x:elx, y:ely, ease: Power4.easeOut}));

            elTimeline.add(TweenMax.to(el, 1, {delay: 2, width:"54px", height:"54px", x:"0%", y: "0%", ease: Power4.easeOut}));

            //elTimeline.add(TweenMax.to(el, 1, {width:"28px", height:"28px", x:elx, y:ely, ease: Power4.easeOut}));
        });

        myTimeline.add(TweenMax.to(plates, 1, {delay: 2, width:"120px", height:"120px", ease: Power4.easeOut }));

        myTimeline.add(TweenMax.to(plates, 1, {delay: 2, width:"80px", height:"80px", ease: Power4.easeOut }));


    }
};




}(jQuery, andrewrossco));
